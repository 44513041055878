'use client'

import React, {
  // FC,
  InputHTMLAttributes,
} from 'react'
// import { UseFormRegister } from 'react-hook-form'
import classnames from 'classnames'
import styles from './Switch.module.scss'
import { ElProps } from './El'

export type SwitchProps = ElProps & InputHTMLAttributes<HTMLInputElement> & {
  active?: boolean
  white?: boolean
  'data-test'?: string
}

export const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(
  ({ active, white, className, ...rest }, inputRef) => {
    const input = classnames(styles.input, className)
    const thumb = classnames(
      styles.track,
      active && white ? styles.activeTrackWhite : undefined,
      active && !white ? styles.activeTrack : undefined
    )
    const track = classnames(
      styles.thumb,
      active && white ? styles.activeThumbWhite : undefined,
      active && !white ? styles.activeThumb : undefined
    )

    return (
      <span className={styles.outerContainer}>
        <input
          className={input}
          type="button"
          role="switch"
          aria-checked={active}
          ref={inputRef}
          aria-invalid="false"
          aria-disabled="false"
          {...rest}
        />
        <span className={thumb} aria-hidden="true">
          <span className={track} aria-hidden="true" />
        </span>
      </span>
    )
  }
)

Switch.displayName = 'Switch'

// export type CheckboxSwitchType = ElProps &
// LabelHTMLAttributes<HTMLLabelElement> & {
//   register: UseFormRegister<any>
//   name: string
//   defaultChecked: boolean
//   white?: boolean
// }

// export const CheckboxSwitch: FC<CheckboxSwitchType> = props => {
//   const { register, name, defaultChecked, white, className, ...rest } = props
//   const c =  classnames(styles.checkboxContainer, white && styles.whiteCheckbox, className)

//   return (
//     <label className={c}>
//       <input
//         {...register(name)}
//         type='checkbox'
//         defaultChecked={defaultChecked}
//         {...rest}
//       />
//       <span>Checkbox Switch</span>
//     </label>
//   )
// }
