import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/next-core/next-core/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/runner/work/next-core/next-core/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/next-core/next-core/workspaces/core/src/components/SVG/NotFound/NotFoundIcon.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/ui/src/lib/El.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/ui/src/lib/Button.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/ui/src/lib/Flex.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/ui/src/lib/Alert.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/ui/src/lib/Center.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/ui/src/lib/FieldSet.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/ui/src/lib/Heading.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/ui/src/lib/Input.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/ui/src/lib/Link.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/ui/src/lib/List.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/ui/src/lib/LoginBox.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/ui/src/lib/Search.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/core/src/components/NotFound.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/ui/src/lib/Switch.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/ui/src/lib/Text.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/ui/src/lib/Textarea.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/ui/src/lib/Spinner.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/ui/src/lib/VisuallyHidden.module.scss");
